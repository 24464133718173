import { useEffect } from "react";
import QuizBoardLandingHeroSection from "./QuizBoardLandingHeroSection";
import QuizBoardLandingFeaturesSection from "./QuizBoardLandingFeaturesSection";
import QuizBoardLandingHowWork from "./QuizBoardLandingHowWork";
import QuizBoardLandingFAQSection from "./QuizBoardLandingFAQSection";
import QuizBoardLandingPageFooter from "./QuizBoardLandingPageFooter";
// import image from "../../../../assets/images/study.jpg";

const QuizBoardLanding =()=>{
  
  // useEffect(()=>{
  //   localStorage.clear();
  // })

  return(
    // <>
    //   <div className="row px-0 d-flex mt-3 p-4 intro-part align-items-center">
    //     <div className="col-6 text-center">
    //         <div className="px-5">
    //           <h1 className="px-4">Welcome Learner</h1>
    //           <h4 className="px-4 mx-1 pt-2 ">Ready to Kick Start your Career With Us !</h4>
    //           <h5 className="p-3">All the Very Best</h5>
    //         </div>
    //     </div>
    //     <div className="col-6 text-center">
    //       <img src={image} alt="into-img" width={350} height={300} />
    //     </div> 
    //   </div>
    // </>
    <>
      <div className="row mx-0">
        <div className="col-12 px-0">
          <QuizBoardLandingHeroSection />
          <QuizBoardLandingFeaturesSection />
          <QuizBoardLandingHowWork />
          <QuizBoardLandingFAQSection />
          <QuizBoardLandingPageFooter />
        </div>
      </div>
    </>
  )
}

export default QuizBoardLanding;