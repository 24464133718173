import AssessmentContinueButton from "../AssessmentContinueButton";
import AssessmentHeader from "./AssessmentHeader";
import AssessmentOptions from "./AssessmentOptions";
import AssessmentQuestions from "./AssessmentQuestions";
import AssessmentTimer from "./AssessmentTimer";
import "./../../../../assets/scss/Assesment/Assessment.scss";
import AssessmentButton from "./AssessmentContinueButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuizTestDetails,
  submitQuizTest,
} from "../../../quiz_board/actions";
import { stopTimer } from "../../Action";
import Loader from "../../../../shared_components/Loader";

const Assessment = () => {
  const { currentQuizTest, quizTestDataLoading } = useSelector(({ quizTest }) => quizTest) || {};
  const { time } = useSelector((state) => state.timer);
  const dispatch = useDispatch();
  const { id } = useParams();

  const quizTestsQuestions = currentQuizTest?.quizQuestions;

  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isloading,setIsLoading] = useState(true)
  

  const navigate = useNavigate();

  useEffect(() => {
    // Only fetch quiz details once, no clearing of answers in localStorage
    localStorage.removeItem("assessment-timer");
    localStorage.removeItem(`quiz-${id}-answers`);

    dispatch(getQuizTestDetails({ quizTest: id, mode: "test" })).then((res) =>{
        setIsLoading(false);
    })
    const savedAnswers = localStorage.getItem(`quiz-${id}-answers`);
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    } else {
      setAnswers([]);
    }

    const handleBeforeUnload = (e) => {
      e.preventDefault();
      return false; // Prevent the default browser warning
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    
    };

  }, [id, dispatch]);

  useEffect(() => {
    console.log("progress is", progress);
  }, [progress]);

  const IncrementProgress = () => {
    const totalQuestions = quizTestsQuestions.length;
    const selectedCount = answers.length;
    const newProgress = Math.round((selectedCount / totalQuestions) * 100);
    console.log("New Progress:", newProgress);
    setProgress(newProgress);
  };
  // Handle timer expiry
  useEffect(() => {
    if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
      localStorage.removeItem("testTimer");
      localStorage.removeItem("testEndTime");
      dispatch(stopTimer());
      handleSubmit();
      navigate(`/assessment/test/${id}/report`);
    }
  }, [time, dispatch, id, navigate]);

  const handlePageChange = (action) => {
    console.log("Page change action:", action);
    if (Array.isArray(quizTestsQuestions)) {
      if (action === "Next" && currentPage < quizTestsQuestions.length - 1) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleClick = () => {
    IncrementProgress();
    handlePageChange("Next");
  };

  const handleOptionChange = (questionId, selectedAnswer) => {
    const updatedAnswers = [...answers];
    const index = updatedAnswers.findIndex(
      (answer) => answer.questionId === questionId
    );

    if (index > -1) {
      updatedAnswers[index].selectedAnswer = selectedAnswer;
    } else {
      updatedAnswers.push({ questionId, selectedAnswer });
    }

    // Save the updated answers to localStorage
    localStorage.setItem(`quiz-${id}-answers`, JSON.stringify(updatedAnswers));
    setAnswers(updatedAnswers);
  };

  const handleSubmit = async () => {
    try {
      const response = {
        quizTestId: id,
        answers: answers,
      };
      dispatch(submitQuizTest(response)).then((res) => {
        if (res) {
          const access_token = localStorage.getItem("access_token");
          const userDetails = localStorage.getItem("userDetails");
          localStorage.clear();
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("userDetails", userDetails);
          navigate(`/assessment/test/${id}/report`);
        }
      });
      console.log("response", response);
    } catch (error) {
      console.error("Error submitting quizTest:", error);
      alert("There was an error submitting your quiz.");
    }
  };


  return (
    <>
      <div className="row mx-0 Assessment-body">
      {isloading ? <Loader />:
        <div className="col-12 px-0">
          <div className="col-12 pt-0">
            <AssessmentHeader progress={progress} logo={currentQuizTest?.cmpLogoUrl}></AssessmentHeader>
          </div>
          <div className="col-12 py-2">
            {quizTestsQuestions && (
              <AssessmentTimer
                timeInMinutes={currentQuizTest?.duration || 1}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
          <div className="row mx-0 border-top justify-content-center align-content-center">
            <div className="col-lg-6 border-end py-3">
              {quizTestsQuestions && (
                <AssessmentQuestions
                  answers={answers}
                  currentPage={currentPage}
                  quizTestsQuestions={quizTestsQuestions}
                  handleOptionChange={handleOptionChange}
                />
              )}
            </div>
            <div className="col-lg-6 py-3">
              <div className="assessment-opt">
                {quizTestsQuestions && (
                  <AssessmentOptions
                    answers={answers}
                    currentPage={currentPage}
                    quizTestsQuestions={quizTestsQuestions}
                    handleOptionChange={handleOptionChange}
                  />
                )}
              </div>
              <div>
                <AssessmentButton
                  currentPage={currentPage}
                  quizTestsQuestions={quizTestsQuestions}
                  handleClick={handleClick}
                  handleSubmit={handleSubmit}
                ></AssessmentButton>
              </div>
            </div>
          </div>
          <div className="row mx-0 p-2 mt-4 d-flex align-items-center border-top text-end">
            <p className="m-0 report-para">Report an Issue <a href="">here</a></p>
          </div>
        </div>
      }
      </div> 
      
    </>
  );
};

export default Assessment;
