import QuizBodyOptions from "./QuizBodyOptions";
import QuizBodyHeader from "./QuizBodyHeader";
import QuizBodyQuestion from "./QuizBodyQuestion";

const PlaygroundBody = ({ handleFlagQuestion, setCurrentPage, flaggedQuestions, isTestActive, answers, currentPage, quizTestsQuestions, handleOptionChange, handlePageChange, handleSubmit }) => {
  return (
    <>
      <section className="px-3">
        <div className="row mx-0 border pb-3 QuizBodySection rounded">
          <div className="col-12 my-0">
            {
              isTestActive ? 
                <>
                  <QuizBodyHeader flaggedQuestions={flaggedQuestions} answers={answers} currentPage={currentPage} handlePageChange={handlePageChange} quizTestsQuestions={quizTestsQuestions} setCurrentPage={setCurrentPage} handleSubmit={handleSubmit} ></QuizBodyHeader>
                  <div className="QuizBody pt-2">
                    <div className="row mx-0 pb-3 QuizBody-Row">
                      <div className="col-lg-6 py-0 border  ">
                        <QuizBodyQuestion answers={answers} handleFlagQuestion={handleFlagQuestion} currentPage={currentPage} quizTestsQuestions={quizTestsQuestions} ></QuizBodyQuestion>
                      </div>
                      <div className="col-lg-6 py-0 QuizBody-col-2 QuizBody-col-3 border border-danger">
                        <QuizBodyOptions answers={answers} handleOptionChange={handleOptionChange} currentPage={currentPage} quizTestsQuestions={quizTestsQuestions}></QuizBodyOptions>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  <h1>Test Ended Times Up</h1>
                </>
            }
          </div>
        </div>
      </section>
    </>
  );
}
export default PlaygroundBody