import { MdOutlineSettings } from "react-icons/md";
import { VscCheck } from "react-icons/vsc";
// import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { submitQuizTest } from "../../../quiz_board/actions";
// import { useState } from "react";
import datacodelogo from "../../../../assets/images/datacodelogo.png"
// import Timer from "../../../../shared_components/Timer";
import AssessmentTimer from "../../../Assessment/component/assessment/AssessmentTimer";

const PlayboardHeader = ({ isTestActive, handleSubmit, handleTimeUp, quizTime,cmpLogo =datacodelogo }) => {
  // const { currentQuizTest } = useSelector(({ quizTest }) => quizTest) || {};

  const quizTestTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return {
        hours: hours,
        minutes: remainingMinutes,
        seconds: 0
    }
  }
  var testUser = JSON.parse(localStorage.getItem("userDetails"));
  // const { hours, minutes, seconds } = quizTestTime(quizTime);

  return (
    <>
      <section className="mt-0">
        <div className="row mx-0 fixed PlayboardHeader-section">
          <div className="col-12 my-0">
            <div className="pb-5 pt-3 PlayboardHeader-row-section">
              <div className="row px-4 mx-0 d-flex  align-items-center">
                <div className="col-lg-2  col-sm-auto col-md-2  my-0 ">
                  <div className="img-fluid d-flex gap-3 align-items-center text-sm-start">
                    <img src={cmpLogo} alt="datacodelogo" height={50} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-auto col-md-6 my-0 border-start">
                  <div className="text-start align-items-center PlayboardHeader-col-2-A ">
                    <div>{testUser && <span>{testUser.data.firstName} {testUser.data.lastName}</span>}</div>
                    {/* <div>Screening-React Developer (Simform Solution... /<small><span><VscCheck /></span> Saved: 0 second go </small></div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-auto col-md-4  my-0 PlayboardHeader-col-3">
                  <div className="d-flex justify-content-end gap-3 align-items-center PlayboardHeader-col-3-A">
                    {/* <div className="">Test Time: */}
                      {isTestActive && (
                        <AssessmentTimer timeInMinutes={quizTime || 1}  handleSubmit ={ handleSubmit } />
                      )}
                        {/* <Timer hours={hours} minutes={minutes} seconds={seconds} onTimeUp={handleTimeUp} /> */}
                     
                    {/* </div> */}
                    <div><span className="text-center align-items-center justify-content-center"><MdOutlineSettings /></span></div>
                    <div className=" align-items-center" ><button onClick={() => handleSubmit()} className="btn btn-outline-light text-center px-lg-3 ">Finish test</button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlayboardHeader;
