import parse from "html-react-parser";

const AssessmentQuestions = ({ quizTestsQuestions, currentPage }) => {
  // Ensure quizTestsQuestions is an array and handle cases where it's empty
  const questionIndex = Array.isArray(quizTestsQuestions) ? currentPage : 0;

  // Handle the case where quizTestsQuestions might be empty or undefined
  const currentQuestion = Array.isArray(quizTestsQuestions) ? quizTestsQuestions[questionIndex] : {};

  // Use optional chaining to safely access the title
  const questionTitle = currentQuestion?.title || 'No title available';
  

  return (
    <>
      <div className="row mx-0">
        <div className="col-12 px-0 py-2">
          <div className="row mx-0">
            <div className="col-12 pb-2">
              <h5>{`Q ${questionIndex + 1}`}</h5>
            </div>
            <div className="col-12 ">
              <h5>{parse(questionTitle)}</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssessmentQuestions;
