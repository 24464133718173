import { IoShieldCheckmark } from "react-icons/io5";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { BsPieChartFill } from "react-icons/bs";
import { FaBrain } from "react-icons/fa";
import img from "../../../../assets/images/how-it-works-works-png-removebg-preview.png";

const QuizBoardLandingHowWork = () => {
  const steps = [
    {
      icon: <IoShieldCheckmark />,
      title: "Sign Up",
      description: "Create an account or log in to get started with personalized quizzes.",
    },
    {
      icon: <HiQuestionMarkCircle />,
      title: "Pick a Quiz",
      description: "Choose from a variety of topics, or let us surprise you with random quizzes.",
    },
    {
      icon: <FaBrain />,
      title: "Test Your Skills",
      description: "Answer questions, track your scores, and aim for high ranks on the leaderboard.",
    },
    {
      icon: <BsPieChartFill />,
      title: "Get Results",
      description: "View your detailed performance report and strive to improve each time.",
    },
  ];

  return (
    <div className="QuizBoardLandingHowWork px-0 container">
      <h3 className="text-center my-4">How It Works</h3>
      <div className="row mx-0 py-4 d-flex align-items-center ">
        {/* Pipeline Section */}
          <div className="col-lg-6 col-sm-12">
            <div className="vertical-pipeline">
              {steps.map((step, index) => (
                <div className="pipeline-step" key={index}>
                  <div className="icon-wrapper">
                    <div className="step-icon">{step.icon}</div>
                    {index < steps.length - 1 && <div className="pipeline-line"></div>}
                  </div>
                  <div className="content">
                    <h5>{step.title}</h5>
                    <p>{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Image Section */}
          <div className="col-lg-6 col-sm-12">
            <img
              src={img}
              alt="How it works"
              className="img-fluid QuizBoardLandingHowWork-img"
            />
          </div>
      </div>
    </div>
  );
};

export default QuizBoardLandingHowWork;
