import { FaChartPie } from "react-icons/fa";
import { IoBarChartSharp, IoHourglassSharp } from "react-icons/io5";
import { TbPencilExclamation, TbReport } from "react-icons/tb";
const QuizBoardLandingFeaturesSection = () => {
  return (
    <>
      <div className="row mx-0 QuizBoardLandingFeaturesSection pt-5 ">
        <div className="col-12 pt-5">
          <div className="">
            <h3 className="text-center">Why Choose QuizTest</h3>
            <div className="d-flex flex-wrap px-lg-5 px-0 justify-content-center align-items-center py-5 gap-5">
              <div className="card QuizBoardLandingFeaturesSection-card text-center">
                <div className="card-body">
                  <span className="py-0 icon-color">
                    <TbPencilExclamation />
                  </span>
                  <h5 className="lh-base">Personalized Quiz Topics</h5>
                  <p className="lh-base">
                    Select from a wide range of categories like React.js,
                    Django, Python, and more tailored to your interests.
                  </p>
                </div>
              </div>
              <div className="card QuizBoardLandingFeaturesSection-card text-center">
                <div className="card-body">
                  <span className="py-0 icon-color">
                    <FaChartPie />
                  </span>
                  <h5 className="lh-base">Real-time Scoring</h5>
                  <p className="lh-base">
                    Get instant feedback and scores to track your progress as
                    you complete each quiz.
                  </p>
                </div>
              </div>
              <div className="card QuizBoardLandingFeaturesSection-card text-center">
                <div className="card-body">
                  <span className="py-0 icon-color">
                    <IoBarChartSharp />
                  </span>
                  <h5 className="lh-base">Interactive Leaderboard</h5>
                  <p className="lh-base">
                    Compete with friends or other users and see how you rank on
                    the leaderboard.
                  </p>
                </div>
              </div>
              <div className="card QuizBoardLandingFeaturesSection-card text-center">
                <div className="card-body">
                  <span className="py-0 icon-color">
                    <IoHourglassSharp />
                  </span>
                  <h5 className="lh-base">Timed Challenges</h5>
                  <p className="lh-base">
                    Challenge yourself with timed quizzes and improve your
                    accuracy under pressure.
                  </p>
                </div>
              </div>
              <div className="card QuizBoardLandingFeaturesSection-card text-center">
                <div className="card-body">
                  <span className="py-0 icon-color">
                    <TbReport />
                  </span>
                  <h5 className="lh-base">Detailed Reports</h5>
                  <p className="lh-base">
                    Receive in-depth reports on your quiz performance, helping
                    you identify strengths and areas for improvement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuizBoardLandingFeaturesSection;
