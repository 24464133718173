import { useState } from "react";
import { FaChevronUp } from "react-icons/fa";
import { Data } from "./AccordianFile";
import { FaAngleDown } from "react-icons/fa";
import img from "./../../../../assets/images/img/faq.jpeg";

const QuizBoardLandingFAQSection = () => {
  const [openIndex, setOpenIndex] = useState([]); // Track which question is open

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        // If already open, remove it from the array
        return prevIndexes.filter((i) => i !== index); //Removing the Index if it’s Already Open:
      } else {
        // If not open, add it to the array
        return [...prevIndexes, index];
      }
    });
  };

  return (
    <>
      <div className="row mx-0 QuizBoardLandingFAQSection">
        <div className="col-12 px-0">
          <h3 className="text-center py-5">Frequently Asked Questions</h3>
          <div className="px-lg-5">
            <div className="row mx-0 d-flex align-items-center justify-content-center">
              <div className="col-lg-10">
                {Data.map((data, index) => (
                  <div className="py-3" key={index}>
                    <div className="QuizBoardLandingFAQSection-box py-2 px-3">
                      <div className=" d-flex justify-content-between">
                        {openIndex.includes(index) ? (
                          <h5 className="text-primary">{data.question}</h5>
                        ) : (
                          <h5 className="">{data.question}</h5>
                        )}
                        <span
                          className=""
                          onClick={() => toggleAccordion(index)}
                        >
                          {openIndex.includes(index) ? (
                            <span className="text-primary">
                              <FaChevronUp />{" "}
                            </span>
                          ) : (
                            <span className="text-dark">
                              <FaAngleDown />
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="">
                        {openIndex.includes(index) && (
                          <p className="QuizBoardLandingFAQSection-answer">
                            {data.answer}
                          </p> // Display answer if this question is open
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="col-lg-5 align-items-center justify-content-center">
                <div className=""></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizBoardLandingFAQSection;
