import { useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import Loader from "../../../../shared_components/Loader";
import { useEffect } from "react";

const AssessmentReport = () => {
  const { quizTestSubmittingLoading } = useSelector((state) => state.quizTest);
  const { id } = useParams();
  const details = JSON.parse(localStorage.getItem("userDetails"));
  const reports = details?.data?.quizReports || [];
  const quizTestReport = Array.isArray(reports)
    ? reports.find((report) => report.quizTest === id)
    : null;

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      navigate("/assessments", { replace: true });
    };
  }, [id, navigate]);

  // Determine success or failure based on total score vs cutoff
  const isSuccess = quizTestReport?.totalScore >= quizTestReport?.cutoff;
  const iconColor = isSuccess ? "#28a745" : "#dc3545";

  return (
    <>
      {quizTestSubmittingLoading ? (
        <Loader />
      ) : quizTestReport ? (
        <div className="row mx-0 mt-5 pt-5 px-3 d-flex justify-content-center assessment-report">
          <div className="col-lg-6 col-sm-12 px-0 report-container">
            <i
              className={`bi ${
                isSuccess ? "bi-check-circle-fill" : "bi-emoji-frown"
              } report-icon`}
              style={{ color: iconColor }}
            ></i>

            <h4 className="report-heading">
              Thank you for Participating in the Assessment Test!
            </h4>

            <h5>
              {isSuccess ? (
                <>
                  Yeah !! You{" "}
                  <span className="score-value" style={{ color: iconColor }}>
                    Passed
                  </span>{" "}
                  this test ⭐
                </>
              ) : (
                <span>Oops !! Better Luck next time.</span>
              )}
            </h5>

            <h5 className="report-score py-2">
              <p className="score-tag" style={{ fontSize: "34px" }}>
                Your Score :{" "}
              </p>
              <p
                className="score-value"
                style={{ color: iconColor, fontSize: "75px" }}
              >
                {quizTestReport?.totalScore}
              </p>
            </h5>

            <p className="footer-note">
              We appreciate your effort. Keep improving!
            </p>
            <Link to="/assessments" replace>
              <p>Back to Assessments</p>
            </Link>
          </div>
        </div>
      ) : (
        <p>Report not found.</p>
      )}
    </>
  );
};

export default AssessmentReport;
