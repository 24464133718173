import { FaFacebook, FaFacebookF, FaInstagramSquare, FaLinkedin, FaLinkedinIn } from "react-icons/fa";
import img from "./../../../../assets/images/datacodelogo.png";
const QuizBoardLandingPageFooter = () => {
  return (
    <>
      <div className="row mx-0 QuizBoardLandingPageFooter pt-5">
        <div className="col-12 px-0 pt-5">
          <div className="row mx-0 d-flex justify-content-center QuizBoardLandingPageFooter-A p-3">
            <div className="col-lg-3 text-center">
              <div>
                <img src={img} alt="img" width={200} height={100} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-8 d-flex justify-content-around  pt-2  fs-5 px-0 QuizBoardLandingPageFooter-Col-2">
              {/* <div className="d-flex justify-content-around pt-2 px-3 gap-md-5 gap-0 g-5 fs-5"> */}
                <div className="col-sm-12 col-md-6 d-flex flex-column lh-lg">
                  <a href>Help center</a>
                  <a href>Information for candidates</a>
                  <a href>Sitemap</a>
                  <a href>Legal stuff</a>
                </div>
                <div className="col-sm-12 col-md-6 d-flex flex-column lh-lg QuizBoardLandingPageFooter-Col-2-B">
                  <a href>
                    Careers <span className= "text-bg-primary p-1">Hiring!</span>
                  </a>
                  <a href>ROI calculator</a>
                  <a href>Blog</a>
                  <a href>Privacy policy</a>
                  <a href>Contact</a>
                </div>
              {/* </div> */}
            </div>
          </div>
          <div className="row mx-0 QuizBoardLandingPageFooter-B py-2">
            <div className="col-lg-12 px-lg-5 text-center">
              <div className="d-lg-flex justify-content-between  align-items-center">
                <div className="fs-5 d-flex gap-4 icon-section align-items-center justify-content-center text-secondary">
                   <a href className="px-2"><FaFacebookF /></a>
                   <a href className="px-2"><FaInstagramSquare /></a>
                   <a href className="px-2"><FaLinkedinIn /></a>
                </div>
                <div className="align-items-center justify-content-center fs-6">
                  <a href className=" text-decoration-none text-secondary fs-6">© Datacode 2024. All rights reserved.</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuizBoardLandingPageFooter;
